import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import Popup from "../components/Popup/Popup";
import GoogleMaps from "../services/GoogleMaps";
import Fade from "react-reveal/Fade";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";

import emailjs from "emailjs-com";

import { useRef } from "react";
import { useState } from "react";
import { useTitle } from "react-use";

const ContactUs = () => {
  useTitle("SMIOC - Contact Us");

  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [validated, setValidated] = useState(false);
  const [statusCode, setStatusCode] = useState();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleMessagelChange = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    e.preventDefault();

    emailjs
      .sendForm("smioc_sunderland_gmail", "smioc", form, "yoUeJVG9hXQ7NKKtO")
      .then(
        (result) => {
          if (result.status === 200) {
            setStatusCode(200);
            setName("");
            setEmail("");
            setMobile("");
            setMessage("");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const mosc_link = (
    <p>
      Visit <a href="https://mosc.in/#">Malankara Orthodox Syrian Church</a> for
      more information about the Syriac Orthodox Church such as the history,
      liturgy and canonical hours, malankara metropolitan, dioceses, spiritual
      organisations etc.
    </p>
  );

  const diocese_link = (
    <p>
      Visit{" "}
      <a href="https://indianorthodoxuk.org">
        Diocese of UK - Europe and Africa
      </a>{" "}
      for more information about the history of the diocese, news, spritual
      leaders, spiritual wings, diocesan council, list of all the parishes and
      their respective key contacts.
    </p>
  );

  const pdfs_link = (
    <p>
      Visit{" "}
      <a href="http://www.malankaraworld.com/library/Prayers/Default.htm">
        Malankara World
      </a>{" "}
      for more information on prayers, key dates, devotional reading and prayer
      books in Malayalam, Manglish and English.
    </p>
  );

  const ss_books_link = (
    <p>
      View all{" "}
      <a href="https://indianorthodoxuk.org/sunday-school-books">
        Sunday School Books
      </a>{" "}
      from Pre-KG to Class 12.
    </p>
  );

  return (
    <Container>
      <Container>
        <Title title="Where to find us?" />
        <Text
          className="center"
          description={
            "We worship on the first Saturday and third Sunday of every month at:\nSt. Cecilia R.C. Church, Ryhope Road, Sunderland, SR2 7TG"
          }
        />
        <Fade big>
          <GoogleMaps
            center={{ lat: 54.891362372774715, lng: -1.3751146609256577 }}
          />
        </Fade>
        <br />
      </Container>

      <Container>
        <Title title="Contact Us" />
        <Fade>
          <Form
            noValidate
            validated={validated}
            ref={form}
            onSubmit={sendEmail}
          >
            <Form.Group className="formgroup" controlId="formBasicEmail">
              <Text
                className="center"
                description="Please get in touch with us if you have any questions or would like to know more information about our church."
              />
              <Form.Label>Name: </Form.Label>
              <Form.Control
                required
                type="text"
                name="from_name"
                value={name}
                onChange={handleNameChange}
              />
              <Form.Control.Feedback></Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please enter your name.
              </Form.Control.Feedback>
              <br />
              <Form.Label>Email: </Form.Label>
              <Form.Control
                required
                type="email"
                name="from_email"
                value={email}
                onChange={handleEmailChange}
              />
              <Form.Control.Feedback></Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
              <br />
              <Form.Label>Mobile: </Form.Label>
              <Form.Control
                required
                type="phone"
                name="from_mobile"
                value={mobile}
                onChange={handleMobileChange}
              />
              <Form.Control.Feedback></Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {" "}
                Please choose a valid mobile number.
              </Form.Control.Feedback>
              <br />
              <Form.Label>Message: </Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={5}
                name="message"
                value={message}
                onChange={handleMessagelChange}
              />
              <Form.Control.Feedback></Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {" "}
                Please enter a message.
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <Button
              className="d-flex justify-content-center submit-button"
              variant="flat"
              type="submit"
              onClick={handleShow}
            >
              Submit
            </Button>
          </Form>
        </Fade>
      </Container>

      <Container>
        <Fade>
          <br />
          <Title title="Key Links" />
          <Row>
            <Fade big cascade>
              <Text className="flex-row" description={mosc_link} />
              <Text className="flex-row" description={diocese_link} />
              <Text className="flex-row" description={pdfs_link} />
              <Text className="flex-row" description={ss_books_link} />
            </Fade>
          </Row>

          <Title title="Key Contacts" />
          <Row>
            <Col lg={4} md={12} sm={12} xs={12}>
              <Text className="bold" description={"Vicar"} />
              <Text
                icon={
                  <BsPersonFill
                    className="icon"
                    size="1.5rem"
                    color="#D9261C"
                  />
                }
                description={"Fr. Varghese P. A."}
              />
              <Text
                className="flex-row"
                icon={
                  <BsFillTelephoneFill
                    className="icon"
                    size="1.5rem"
                    color="#D9261C"
                  />
                }
                description={"+44 7771 147764"}
              />
              <Text
                className="flex-row"
                icon={
                  <MdEmail className="icon" size="1.6rem" color="#D9261C" />
                }
                description={
                  <>
                    <a href="mailto:vicar@stmarysiocsunderland.org">
                      vicar@stmarysiocsunderland.org
                    </a>
                  </>
                }
              />
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <Text className="bold" description={"Secretary"} />
              <Text
                className="flex-row"
                icon={
                  <BsPersonFill
                    className="icon"
                    size="1.5rem"
                    color="#D9261C"
                  />
                }
                description={"Susannamma Thomas"}
              />
              <Text
                className="flex-row"
                icon={
                  <BsFillTelephoneFill
                    className="icon"
                    size="1.5rem"
                    color="#D9261C"
                  />
                }
                description={"+44 7473 627172"}
              />
              <Text
                className="flex-row"
                icon={
                  <MdEmail className="icon" size="1.6rem" color="#D9261C" />
                }
                description={
                  <>
                    <a href="mailto:secretary@stmarysiocsunderland.org">
                      secretary@stmarysiocsunderland.org
                    </a>
                  </>
                }
              />
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <Text className="bold" description={"Trustee"} />
              <Text
                className="flex-row"
                icon={
                  <BsPersonFill
                    className="icon"
                    size="1.5rem"
                    color="#D9261C"
                  />
                }
                description={"Sabu Kadavanthanam"}
              />
              <Text
                className="flex-row"
                icon={
                  <BsFillTelephoneFill
                    className="icon"
                    size="1.5rem"
                    color="#D9261C"
                  />
                }
                description={"+44 7859 833078"}
              />
            </Col>
          </Row>
        </Fade>
      </Container>

      <Container>
        <Popup
          title="Success"
          body="Thank you for your email. We will be in touch shortly."
          statusCode={statusCode}
          show={show}
          handleClose={handleClose}
        />
      </Container>
      <br />
      <br />
    </Container>
  );
};

export default ContactUs;
