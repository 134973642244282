import SupremeThirumeni from "../assets/media/officebearers/supremethirumeni.png";
import Thirumeni from "../assets/media/officebearers/thirumeni.jpg";
import VarugheseBabu from "../assets/media/officebearers/varughesebabu.png";
import VarghesePa2 from "../assets/media/officebearers/varghesepa2.png";
import HappyJacob from "../assets/media/officebearers/happyjacob.png";
import MerinShaji from "../assets/media/officebearers/merineldhose.png";
import SabuKadavanthanam5 from "../assets/media/officebearers/sabukadavanthanam5.png";
import BabuVarughese from "../assets/media/officebearers/babuvarughese.png";
import MohanGeorge from "../assets/media/officebearers/mohangeorge.png";
import LijoMathew from "../assets/media/officebearers/lijomathew.png";
import BijiVarughese from "../assets/media/officebearers/bijivarughese.png";
import KunjumoleJoy from "../assets/media/officebearers/kunjumolejoy.png";
import RiyaBaiju from "../assets/media/officebearers/riyabaiju.png";
import NadiyaIype from "../assets/media/officebearers/nadiyaiype.png";
import SusanThomas from "../assets/media/officebearers/susanthomas.png";
import JinuGeorge from "../assets/media/officebearers/jinugeorge.png";
// import PlaceHolder from "../assets/media/officebearers/placeholder.png";

export const metropolitans = [
  {
    title: (
      <>
        Catholicos of East &<br /> Malankara Metropolitan
      </>
    ),
    name: "H.H. Baselios Marthoma Mathews III",
    href: "https://mosc.in/holysynod/his-holiness-baselios-marthoma-mathews-iii",
    image: SupremeThirumeni,
  },
  {
    title: (
      <>
        Diocesan Metropolitan of <br />
        UK, Europe & Africa
      </>
    ),
    name: "H. G. Abraham Mar Stephanos",
    href: "https://mosc.in/holysynod/h-g-abraham-mar-stephanos-metropolitan",
    image: Thirumeni,
  },
];

export const officebearers = [
  {
    title: "Vicar",
    name: "Rev. Fr. Varghese P. A.",
    image: VarghesePa2,
  },
  {
    title: "Trustee",
    name: "Sabu Kadavanthanam",
    image: SabuKadavanthanam5,
  },
  {
    title: "Secretary",
    name: "Susannamma Thomas",
    image: SusanThomas,
  },
  {
    title: "Committee Member",
    name: "Babu Varughese",
    image: BabuVarughese,
  },
  {
    title: "Committee Member",
    name: "Biji Varughese",
    image: BijiVarughese,
  },
  {
    title: "Committee Member",
    name: "Lijo Mathew",
    image: LijoMathew,
  },
  {
    title: "Ex-Officio",
    name: "Dr. Mohan George",
    image: MohanGeorge,
  },
  {
    title: "Ex-Officio",
    name: "Kunjumole Joy",
    image: KunjumoleJoy,
  },
  {
    title: "Internal Auditor",
    name: "Nadiya Iype",
    image: NadiyaIype,
  },
  {
    title: "Safeguarding Officer",
    name: "Jinu George",
    image: JinuGeorge,
  },
  
];

export const spiritual_organisers = [
  {
    title: "MMVS Secretary",
    name: "Merin Shaji",
    image: MerinShaji,
  },
  {
    title: "Sunday School Head Teacher",
    name: "Riyamol Rajan",
    image: RiyaBaiju,
  },
  {
    title: "Youth Coordinator",
    name: "Varughese Babu",
    image: VarugheseBabu,
  },
];

export const msca = [
  {
    title: "MSCA Representative",
    name: "Rev. Fr. Happy Jacob",
    href: "https://mosc.in/administration/malankara-association",
    image: HappyJacob,
  },
  {
    title: "MSCA Representative",
    name: "Sabu Kadavanthanam",
    href: "https://mosc.in/administration/malankara-association",
    image: SabuKadavanthanam5,
  },
];

export const msca_diocese = [
  {
    title: "Diocese of UK, Europe & Africa Representative",
    name: "Dr. Mohan George",
    href: "https://mosc.in/administration/malankara-association",
    image: MohanGeorge,
  },
];
