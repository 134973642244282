export const events = [
  {
    title: "Holy Qurbana",
    date: "2025-03-16",
    time: "9am",
    description: "Followed by Unit Level Talent Day",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Feast of Annunciation to St. Mary",
    date: "2025-03-24",
    time: "6:30pm",
    description: "Vachanipu Perunal. Evening Prayer followed by Holy Qurbana",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },

  {
    title: "Holy Qurbana",
    date: "2025-04-05",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "40th Friday of Great Lent",
    date: "2025-04-10",
    time: "6:30pm",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Palm Sunday",
    date: "2025-04-13",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Prayer Meeting",
    date: "2025-04-14",
    time: "7pm",
    description: "",
    location: <>Members Residence - Biji Varughese</>,
  },
  {
    title: "Prayer Meeting",
    date: "2025-04-15",
    time: "7pm",
    description: "",
    location: <>Members Residence - Babu Varughese</>,
  },
  {
    title: "Pesaha Perunnal",
    date: "2025-04-16",
    time: "6:30pm",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Prayer Meeting",
    date: "2025-04-17",
    time: "6:30pm",
    description: "",
    location: <>Members Residence - Lijo Mathew</>,
  },
  {
    title: "Good Friday",
    date: "2025-04-18",
    time: "7am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Saturday",
    date: "2025-04-19",
    time: "8am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church Hall <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Easter",
    date: "2025-04-19",
    time: "6:30pm",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-05-03",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-05-18",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Feast of Ascension",
    date: "2025-05-28",
    time: "6:30pm",
    description: "Swargarohana Perunnal",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-05-31",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-06-15",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-07-05",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-07-20",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-08-02",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Feast of the Transfiguration",
    date: "2025-08-05",
    time: "6:30pm",
    description: "Koodara Perunal",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Feast of the Assumption of St. Mary",
    date: "2025-08-05",
    time: "6:30pm",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-08-17",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-09-06",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-09-21",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-10-04",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-10-19",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Feast of St. Gregorios of Parumala",
    date: "2025-11-01",
    time: "9am",
    description: "Parumala Perunnal",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-11-16",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-12-06",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-12-21",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Christmas",
    date: "2025-12-21",
    time: "TBC",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
];
